import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import 'animate.css';
import Button from 'react-bootstrap/Button';
import backIcon from '../backIcon.png';
import './BirthdayComponentHeader.css';
import { ToastContainer, toast } from 'react-toastify';
import ChooseInfo from '../DisplayInfo/ChoicePage/ChooseInfo';
import { postBirthday } from '../../services/postBirthday';
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const daysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const BirthdayComponentHeader = () => {
  const constraintsRef1 = useRef(null);
  const innerBoxRef = useRef(null);
  const [showResults, setShowResults] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [userInfo, setUserInfo] = useState(null)
  // const [showMonth, setShowMonth] = useState(true);
  // const [showDay, setShowDay] = useState(false);
  // const [showYear, setShowYear] = useState(false);
  const [count, setCount]= useState(0)
  const handleDragEnd = (event, info) => {
    const { x, y } = info.point;
    const boxRect = constraintsRef1.current.getBoundingClientRect();
    const relativeX = x - boxRect.left;
    const relativeY = y - boxRect.top;
    const columns = 4;
    const rows = 3;
    const columnWidth = boxRect.width / columns;
    const rowHeight = boxRect.height / rows;
    const column = Math.floor(relativeX / columnWidth);
    const row = Math.floor(relativeY / rowHeight);
    const monthIndex = column + row * columns;
    const selectedMonth = months[monthIndex];
    setSelectedMonth(selectedMonth);
  };

  const handleAddCount = () => {
    if (count ===0 && selectedMonth == null){
         toast.error("Please Select Month", { theme: "dark" })
    }
    else if(count ===1 && selectedDay == null){
      toast.error("Please Select Day", { theme: "dark" })
    }
    else{
      setCount(count + 1)

    }

  };
  const handleDecreaseCount = ()=>{
    setCount(count-1)
  }



  const handleConfirmYear = () => {
    if(selectedYear === null){
      toast.error("Please Select Year", { theme: "dark" })
    }
    else{
      postBirthdayInformation()
      console.log(userInfo)
      const body = document.body
      
      const yearContainer = document.getElementsByClassName("container")[0];
      const otherInfo = document.getElementsByClassName("otherInfoHolder")[0];
      // Check if both elements are found
      if (yearContainer && otherInfo) {
        // Add a new class to the element
        yearContainer.classList.add("animate__fadeOut");
        otherInfo.classList.add("animate__fadeOut");

        
        setTimeout(()=>{
          yearContainer.remove()
        otherInfo.remove()
        setShowResults(true)

        }, 3000)
        
      }




    }

  };

  const generateDayOptions = () => {
    const days = Array.from({ length: daysInMonth(selectedYear, months.indexOf(selectedMonth)) }, (_, index) => index + 1);
    return days.map((day) => (
      <motion.div
        key={day}
        style={{
          width: '100%',
          height: '100%',
          transition: 'all 0.5s ease',
          backgroundColor: selectedDay === day ? 'rgba(186, 85, 211, 0.5)' : 'transparent',
          borderRadius: '5px',
          color: 'white',
          cursor:"pointer"
        }}
        onClick={() => { setSelectedDay(day); setCount(count + 1); }}
      >
        <div
          className="dayLabel"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {day}
        </div>
      </motion.div>
    ));
  };

  const generateYearOptions = () => {
    const startYear = 1940;
    const endYear = 2023;
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    return years.map((year) => (
      <motion.div
        key={year}
        style={{
          width: '100%',
          height: '100%',
          transition: 'all 0.5s ease',
          backgroundColor: selectedYear === year ? 'rgba(186, 85, 211, 0.5)' : 'transparent',
          borderRadius: '5px',
          color: 'white',
          cursor:"pointer"

        }}
        onClick={() => setSelectedYear(year)}
      >
        <div
          className="yearLabel"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {year}
        </div>
      </motion.div>
    ));
  };
  const postBirthdayInformation = ()=>{
    const birthdayInfo = selectedMonth +" " +  selectedDay + " " + selectedYear
    console.log(birthdayInfo)
    localStorage.setItem("birthday", birthdayInfo)
    postBirthday({ birthdayInfo })
    .then((data) => {
      console.log(data);
      setUserInfo({ data });
    })
    .catch((error) => {
      console.error('Error while posting birthday information:', error);
      // Handle the error as needed, e.g., show an error message to the user.
    });
    
  }



  return (
    <div>
      <div>
        {showResults && <ChooseInfo userData={userInfo}/>}

      <div className="birthdayComponentContainer">
        <div className="otherInfoHolder animate__animated">
      <div className="enterBirthdayPrompt animate__animated animate__zoomIn">
        {count > 0 && <a onClick={handleDecreaseCount}><img src={backIcon} alt="backButton" className="backButton animate__animated animate__zoomIn"/></a>}
        {count === 0 && <h5><i>Enter Birthday</i></h5>}
      </div>
    <div className="dateText">
      {selectedMonth && <h3 className="animate__animated animate__zoomIn">{selectedMonth}</h3>}
      {selectedDay !== undefined && <h3 className="animate__animated animate__zoomIn">{selectedDay}</h3>}
      {selectedYear !== undefined && <h3 className="animate__animated animate__zoomIn">{selectedYear}</h3>}
    </div>
    <div className="buttonContainer animate__animated">
      <br />
      { count === 2 ? (
  <Button variant="outline-light" onClick={handleConfirmYear}>
    Confirm Birthday
  </Button>
) : null}

    </div>
    </div>
    <div className="container animate__animated">
      {count === 0 && (
        <div>
          <motion.div
            className="outerBox animate__animated animate__zoomIn"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: '10px',
              width: '350px',
              height: '350px',
              backgroundColor: 'transparent',
              position: 'relative',
              cursor: 'pointer', // Set pointer cursor for the entire month selector
            }}
            ref={constraintsRef1}
          >
            {months.map((month, index) => (
              <motion.div
                key={month}
                style={{
                  width: '100%',
                  height: '100%',
                  transition: 'all 0.5s ease',
                  backgroundColor: selectedMonth === month ? 'rgba(186, 85, 211, 0.5)' : 'transparent',
                  borderRadius: '5px',
                  color: 'white',
                }}
                onClick={() => { setSelectedMonth(month); setCount(count + 1); }}
              >
                <div
                  className="monthLabel"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  {month}
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      )}
      {count === 1 && (
        <motion.div
          className="daySelector animate__animated animate__zoomIn"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gridGap: '10px',
            width: '300px',
            height: '300px',
            backgroundColor: 'transparent',
            position: 'relative',
            cursor: 'pointer', // Set pointer cursor for the entire day selector
          }}
        >
          {generateDayOptions()}
        </motion.div>
      )}
      {count === 2 && (
        <motion.div
          className="yearSelector animate__animated animate__zoomIn"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridGap: '10px',
            width: '300px',
            height: '300px',
            backgroundColor: 'transparent',
            position: 'relative',
            cursor: 'pointer', // Set pointer cursor for the entire year selector
          }}
        >
          {generateYearOptions()}
        </motion.div>
      )}
    </div>
    </div>
  </div>
  </div>
  );
};

export default BirthdayComponentHeader;

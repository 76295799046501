import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Navigation from './components/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import InfoButton from './components/Information/InfoButton';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <InfoButton/>
      <Navigation/>
         <Home/>
    </div>
  );
}

export default App;

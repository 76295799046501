import React from 'react';
import './NumerologyDescription.css'; // Import your CSS file for styling
import { Accordion } from 'react-bootstrap';

const NumerologyDescription = ({ userData, description }) => {
  if (!description) {
    return <p>Loading...</p>;
  }

  return (
    <div className="numerology-description-container animate__animated animate__zoomIn">
      <h3 className="life-path-number">Life Path Number: {userData.data.lifePathNum}</h3>
      <Accordion defaultActiveKey="0" className="custom-accordion">
        {Object.entries(description).map(([key, value], index) => (
          <React.Fragment key={index}>
            <Accordion.Item eventKey={index} className="custom-accordion-item">
              <Accordion.Header className="custom-accordion-header">
                {capitalizeFirstLetter(key)}
              </Accordion.Header>
              <Accordion.Body className="custom-accordion-body">{value}</Accordion.Body>
            </Accordion.Item>
          </React.Fragment>
        ))}
      </Accordion>
    </div>
  );
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};


export default NumerologyDescription;

// ZodiacDescription.js

import React from 'react';
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './ZodiacDescription.css';

const ZodiacDescription = ({ description }) => {
  if (!description) {
    return <p>Loading...</p>;
  }

  return (
    <div className="zodiacContainer animate__animated animate__zoomIn">
     <h3 className="zodiac-title">{description.name}</h3>
      <h5 className="zodiac-description">{description.description}</h5>

      <Accordion defaultActiveKey="0" className="custom-accordion">
        {Object.entries(description.traits).map(([key, value], index) => (
          <React.Fragment key={index}>
            <Accordion.Item eventKey={index} className="custom-accordion-item">
              <Accordion.Header className="custom-accordion-header">
                {capitalizeFirstLetter(key)}
              </Accordion.Header>
              <Accordion.Body className="custom-accordion-body">{value}</Accordion.Body>
            </Accordion.Item>
          </React.Fragment>
        ))}
      </Accordion>
    </div>
  );
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default ZodiacDescription;

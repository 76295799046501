import React from 'react'
import Logo from '../MeandMeLogo.png'
import './Navigation.css'
const Navigation = () => {
  return (
    <div className="navigationHeader">
        <p>MeandMe</p>
    </div>
  )
}

export default Navigation
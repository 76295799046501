import React, {useState} from 'react'
import icon from './i.png'
import "./InfoButton.css"
import InfoModal from './InfoModal'
const InfoButton = () => {
    const [showInfoModal, setShowInfoModal] = useState(0)
    const handleShowInfoModal = () => {
        setShowInfoModal(prev => prev + 1);
      };
      

    
    return (
        <div>
        <div className="iconContainer">
            
            
            <img src={icon} alt="infoIcon" className="infoIcon" onClick={(()=>{handleShowInfoModal()})}/>
            
        </div>
        <InfoModal showModalVar={showInfoModal} />


        </div>
    )
}


export default InfoButton

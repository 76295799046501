export async function getZodiacDescription( zodiac ) {
    console.log(zodiac)
    const url = "https://meandmebackend.darien-c.com/backend/getZodiacDescription/";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify({"sign" : zodiac}), // Convert the data to JSON format
    });
  
    // Assuming you want to handle the response here
    if (response.ok) {
      const data = await response.json();
      // Handle the data
      console.log(data);
      return data
    } else {
      // Handle the error
      console.error("Failed to post birthday information");
    }
  }
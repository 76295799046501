import React, { useState,  useEffect } from 'react';
import { Modal, Accordion, Card, Button } from 'react-bootstrap';
import './InfoModal.css'
const InfoModal = ({ showModalVar }) => {
   const [showModal, setShowModal] = useState(false)

   useEffect(()=>{
    if(showModalVar !== 0){
        setShowModal(true)
    }
   }, [showModalVar])

  return (
    <div>
      <Modal show={showModal} onHide={(()=>{setShowModal(false)})}>
        <Modal.Header>
        <Modal.Title className="text-center" style={{ margin: 'auto' }}>
About MeandMe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          MeandMe is a free web application that provides users with information about their unique astrology and numerology origins.
          
          <Accordion className="infoAccordion">
            
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What is Numerology?</Accordion.Header>
                  
                
           
              <Accordion.Body>
              
                  Numerology is the study of the mystical significance of numbers and their influence on human life.
                  It involves analyzing and interpreting the numbers derived from a person's name and birth date to reveal insights into their personality, strengths, weaknesses, and life path.
              </Accordion.Body>
              </Accordion.Item>
           

           
                <Accordion.Item eventKey="1"
                  
                >
                    <Accordion.Header>What is Astrology?</Accordion.Header>
                  
                
            
              <Accordion.Body>
                
                  Astrology is the study of the positions and movements of celestial bodies, such as planets and stars,
                  and their potential influence on human affairs and natural events. It involves creating a natal chart based on an individual's birth details to provide insights into their personality, relationships, and life events.
                
              </Accordion.Body>
              </Accordion.Item>
           
          </Accordion>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InfoModal;

import React, { useEffect, useState } from 'react';
import './ChooseInfo.css';
import backIcon from './backIcon.png';

import Carousel from 'react-bootstrap/Carousel';
import { getZodiacDescription } from '../../../services/getZodiacDescription';
import { getNumerologyDescription } from '../../../services/getNumberologyDescription';
import ZodiacDescription from '../ZodiacDes/ZodiacDescription';
import NumerologyDescription from '../ZodiacDes/NumerologyDescription';
const ChooseInfo = ({ userData }) => {
  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const [showDes, setShowDes] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null)
  const [numerologyDescription, setNumerologyDescription] = useState(null)
  const [zodiacDescription, setZodiacDescription] = useState(null)

  const handleZodiacItemClick = async() => {
    const carouselItem = document.getElementsByClassName("astrologyOption")[0]
    carouselItem.classList.add("animate__fadeOutDown");
    setSelectedItem("zodiac")
    
    const sign = userData.data.astrologySign

    const responseData = await getZodiacDescription(sign);
    console.log(responseData);
    setZodiacDescription(responseData["Sign Information"])
  
  
    setTimeout(()=>{
      setShowDes(true);
    }, 1500)
    
  };
  const handleNumerologyItemClick = async() => {
    console.log("clicked")
    const carouselItem = document.getElementsByClassName("astrologyOption")[0]
    carouselItem.classList.add("animate__fadeOutDown");
    setSelectedItem("numerology")
    const number = userData.data.lifePathNum;
    const responseData = await getNumerologyDescription(number);
    console.log(responseData)
    setNumerologyDescription(responseData['Description_Information']);
    console.log(numerologyDescription)
    setTimeout(()=>{
      setShowDes(true);
    }, 1500)
    
  };

  useEffect(() => {
    console.log(showDes);
  }, [showDes]);
  const textStyle = {
    fontFamily: "'Arial', sans-serif",
    // Add any other styling properties as needed
  };

  return (
    <div className="ChooseInfo">

      {showDes ? (
                // Conditional rendering based on the selected item
                selectedItem === "zodiac" ? (
                  <div>

                  <div className="backButtonContainer">
                          <img src={backIcon} alt='backButton' className="backButton" onClick={(()=>setShowDes(false))}/>

                        </div>
                        <ZodiacDescription userData={userData} description={zodiacDescription}/>

                  </div>
                ) : selectedItem === "numerology" ? (
                  <div>
                           <div className="backButtonContainer">
                          <img src={backIcon} alt='backButton' className="backButton" onClick={(()=>setShowDes(false))}/>

                        </div>
                        <NumerologyDescription userData={userData} description={numerologyDescription}/>

                    
                  </div>
                ) : null
              ) : (
        <div className="astrologyOption animate__animated animate__fadeIn">
          <Carousel className="chooseInfoCarousel">
            <Carousel.Item>
              <div
                className="carousel-background"
                style={{
                  background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 0%)',
                  height: '60vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleZodiacItemClick}
              >
                <img
                  src={`/ZodiacImgs/${userData.data.astrologySign}.png`}
                  alt={`${userData.data.astrologySign} symbol`}
                  style={{ width: '30%', height: 'auto' }}
                />
                <Carousel.Caption>
                  <h3>{userData.data.astrologySign}</h3>
                  {userData.data.astrologyDescription.map((description, index) => (
                    <span key={index}>{description} </span>
                  ))}
<p style={textStyle}>
      <b>
        <u>Click For Detailed Description</u>
      </b>
    </p>                </Carousel.Caption>
              </div>
            </Carousel.Item>

            <Carousel.Item         onClick={handleNumerologyItemClick}
>
      <div
        className="carousel-background"
        style={{
          background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 0%)',
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex:5
        }}
        onClick={handleNumerologyItemClick}

      >
        <p style={{ fontSize: '5rem', fontWeight: 'bold' }}>{userData.data.lifePathNum}</p>
      </div>
      <Carousel.Caption>
        <h3>Life Path Number: <b><u>{userData.data.lifePathNum}</u></b></h3>
        <p>{userData.data.numerologyInfo}</p>
        <p style={textStyle} className="clickHereDes">
          <b>
            <u>Click For Detailed Description</u>
          </b>
        </p>
      </Carousel.Caption>
    </Carousel.Item>
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default ChooseInfo;

import React from 'react'
import BirthdayComponentHeader from '../components/BirthdayComponents/BirthdayComponentHeader'
import './Home.css'
import Tiles from '../components/Tiles'
const Home = () => {
  return (
    <div className="tiles">
         <BirthdayComponentHeader/>
        
    </div>
  )
}

export default Home